//global.jQuery = require('jquery');
//require('name-of-package');

(function( $ ) {

	$(function() {
		// doc ready

        // Toggle
        var menuStatus = false;
        $(".menu-toggle").click(function() {
            if (menuStatus == false) {
                $(".menu-wrapper").css("display", "block");
                menuStatus = true;
            } else {
                $(".menu-wrapper").css("display", "none");
                menuStatus = false;
            }
        });
        $(".close-mobile").click(function() {
            if (menuStatus == true) {
                $(".menu-wrapper").css("display", "none");
                menuStatus = false;
            }
        });

        $(".carousel").slick({
            dots: true,
            arrows: false,
            infinite: true,
            speed: 500,
            fade: true,
            cssEase: 'linear'
        });




        // Menu - Scroll to section #ID
        $('.menu-wrapper ul li').click(function(event){
            event.preventDefault();
            console.log($(this).find('a').attr('href'));
            var dest = $(this).find('a').attr('href');
            var headerOffset = $('.header').outerHeight();
            $("html, body").animate({ scrollTop: $(dest).offset().top - headerOffset }, { easing: 'easeInOutExpo', duration: 800});
        });

        // Current menu Item
        /*$('.content').each(function(){
            var elId = '#' + $(this).attr('id');
                $(elId).on('inview', function(event, isInView) {
                  if (isInView) {
                    // element is now visible in the viewport
                    $('.menu-wrapper li').removeClass('active');
                    $('.menu-wrapper li a[href="'+elId+'"]').parent().addClass('active');
                  } else {
                    // element has gone out of viewport
                  }
                });
            });*/




        });

})(jQuery);